import React from 'react'
import styled from "styled-components"
import PageHeader from "../elements/pageHeader"
import Map from './map'
import {Link, useStaticQuery, graphql} from "gatsby"
import Img from "gatsby-image"

const Location = () => {

  const image = useStaticQuery(graphql`
    query {
      file(name: {eq: "aerialmap"}) {
        childImageSharp {
          fluid {
            ...GatsbyImageSharpFluid
          }
        }
      }
    }
  `)

  return (
    <>
      <PageHeader header="Location" subHeader="Steps away from Chesterman Beach" />
      <LocationContainer>
        <LocationWrapper>
          <MapWrapper>
           <Map />
          </MapWrapper> 
          <ContactWrapper>
            <Address>
              <span>Gold Coast Retreat</span><br />
              1338 &amp; 1340 Chesterman Beach Road<br />
              Box 3 <br />
              Tofino, BC V0R 2Z0 <br />
            </Address>  
            <Contact>
              <p>To inquire about reservations and vacancies please e-mail, phone or visit the <Link to="/reservations">reservations page</Link>.</p>
              <ul>
                <li>Phone: <a href="tel:250-725-3789">250-725-3789</a></li>
                <li>E-mail: <a href="mailto:info@goldcoasttofino.com">info@goldcoasttofino.com</a></li>
              </ul>
            </Contact>
          </ContactWrapper> 
        </LocationWrapper>
      </LocationContainer>
      <ImageContainer>
        <LoactionImage fluid={image.file.childImageSharp.fluid} alt="Aerial map"/>
      </ImageContainer>
    </>
  )
}

export default Location

const LocationContainer = styled.div`
  padding: 5rem calc((100vw - 1200px) / 2);
  display:flex;
  flex-direction: column;
  margin-bottom: 1rem;
`
const LocationWrapper = styled.div`
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  align-items: center;

  @media screen and (max-width: 868px){
    grid-template-columns: 1fr;
  }
`
const MapWrapper = styled.div`
  justify-self: center;
`

const ContactWrapper = styled.div`  
  margin-left: 1rem;
  justify-self: center;
`

const Address = styled.p`
  span {
    font-weight: 600;
    font-size: 1.2rem;
  }

  @media screen and (max-width: 868px){
    margin-top: 3rem;
  }
`

const Contact = styled.div`
  p{
    font-size: 1.2rem;
  }

  ul {
    padding-left: 5%;
  }

  a {
    text-decoration: none;
    color: #1673b5;
  }
`

const ImageContainer = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  background: #093656;
  padding: 50px 0 100px 0;
`

const LoactionImage = styled(Img)`
  margin: auto;
  border-radius: 0px;
  width: 800px;
  height: 100%;
`